import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icon for "24 timmar avbokning"
import DiscountIcon from '@mui/icons-material/Discount'; // Icon for "Visdomsrabatt"
import SecurityIcon from '@mui/icons-material/Security'; // Icon for "15-års gräns"
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const membershipsData = [
  { title: 'Månadskort', price: '650 kr/mån', description: 'Träna obegränsat på våra schemalagda klasser. 3 månaders bindningstid.' },
  { title: 'Månadskort Ungdom/Pensionär', price: '550 kr/mån', description: 'Obegränsad träning på våra klasser för ungdomar och pensionärer. 3 månaders bindningstid.' },
  { title: 'Tower Intro', price: '1250 kr', description: 'Lär dig grunderna i Klassisk Pilates på 5 tillfällen med start vecka 42. Vi förbereder dig för att tryggt och effektivt delta i våra ordinarie klasser.' }
];

const Memberships = () => (
  <Container sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
    <Typography 
      variant="h4" 
      align="center" 
      sx={{ 
        fontWeight: 'bold', 
        color: '#333', 
        marginBottom: '2rem', 
        fontSize: { xs: '2rem', md: '2.5rem' } // Responsive font size for heading
      }}
    >
     Vårt Utbud
    </Typography>
    
    <Grid container spacing={4} justifyContent="center">
      {membershipsData.map((membership, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between', 
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)', 
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
            },
            padding: '1.5rem',
            borderRadius: '12px', // Rounded corners for modern look
          }}>
            <CardContent sx={{ 
              textAlign: 'center', 
              flexGrow: 1,
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Box sx={{ marginBottom: '1.5rem' }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#00b5bf', 
                    fontSize: '1.5rem', 
                    textTransform: 'uppercase', 
                    marginBottom: '1rem',
                  }}
                >
                  {membership.title}
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: '#333', 
                    fontSize: '1.25rem', 
                    fontWeight: 'bold',
                  }}
                >
                  {membership.price}
                </Typography>
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  marginBottom: '1.5rem', 
                  color: '#666',
                  fontSize: { xs: '0.875rem', md: '1rem' } // Adjust font size for readability
                }}
              >
                {membership.description}
              </Typography>
            </CardContent>
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#A2257C', 
                color: '#fff', 
                borderRadius: '20px', 
                padding: '0.75rem 2rem',
                '&:hover': {
                  backgroundColor: '#007C85',
                },
                fontSize: { xs: '0.875rem', md: '1rem' }, // Responsive button text size
              }} 
              href="/sidepages/membership-info"
            >
              Se Mer
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>

    <Box sx={{ marginTop: '5rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <CardMembershipIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
        Frysning av medlemskort utan sjukintyg kostar 150 kr, och en avgift på 150 kr tillkommer vid återaktivering.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <DiscountIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Visdomsrabatt får de som inte fyllt 18 år än och de som fyllt 65 år.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <SecurityIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Vi har 15-års gräns i studion.
        </Typography>
      </Box>
    </Box>
    
    {/* Button placed below the membership cards */}
    <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
      <Button 
        variant="contained" 
        sx={{ 
          backgroundColor: '#00b5bf', 
          color: '#fff', 
          marginTop: '24px',
          borderRadius: '20px', 
          padding: '0.75rem 2.5rem', 
          fontSize: { xs: '0.875rem', md: '1rem' },
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', 
          transition: 'background-color 0.3s, transform 0.3s',
          '&:hover': {
            backgroundColor: '#007C85',
            transform: 'scale(1.05)',
          }
        }} 
        href="/sidepages/membership-info"
      >
        Se Utbud
      </Button>
    </Box>
  </Container>
);

export default Memberships;
