// src/components/MembershipInfo.js
import { Container, Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icon for "24 timmar avbokning"
import DiscountIcon from '@mui/icons-material/Discount'; // Icon for "Visdomsrabatt"
import SecurityIcon from '@mui/icons-material/Security'; // Icon for "15-års gräns"
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const MembershipInfo = () => (
  <Container sx={{ padding: '4rem 0', maxWidth: 'lg' }}>
    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
      Våra Medlemskap
    </Typography>
    <Typography variant="body1" align="center" paragraph sx={{ color: '#555', marginBottom: '2rem', fontSize:'1.2rem' }}>
      Utforska våra medlemskap och hitta det som passar dig bäst. Anmäl dig genom att klicka på en av alternativen nedan.
    </Typography>

    {/* Membership Info with Icons */}
    <Box sx={{ marginTop: '3rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <AccessTimeIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Personlig träning ska avbokas senast 24 timmar innan, annars debiteras hela kostnaden.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <CardMembershipIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
        Frysning av medlemskort utan sjukintyg kostar 150 kr, och en avgift på 150 kr tillkommer vid återaktivering.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <DiscountIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Visdomsrabatt får de som inte fyllt 18 år än och de som fyllt 65 år.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <SecurityIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Vi har 15-års gräns i studion.
        </Typography>
      </Box>
    </Box>

    {/* Box for iframe */}
    <Box sx={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '8px', marginTop: '2rem', overflowX: 'auto',
        overflowY: 'hidden' }}>
      <iframe
        src="https://PILATESHOGANAS.wondr.se/register/"
        width="100%"
        height="1000em"
        frameBorder="0"
        style={{ border: 'none', borderRadius: '8px' }}
        title="Membership Registration"
      ></iframe>
    </Box>
  </Container>
);

export default MembershipInfo;
